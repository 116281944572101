import React from "react";
import App from "../App";
import Navbar from "../Component/navbar";
import Footer from "../Component/Footer";

function Layout() {
  return (
   <>
   <div className="relative">
     <div className="navboxshadow fixed w-[100%] z-[99]">
      <Navbar />
      </div>
    <App />
    <div>
        <Footer />
    </div>
   </div>
   </>
  );
}

export default Layout;
