import React from "react";
import iconsb1 from "../assets/svgs/peopleicn  (1).svg";
import iconsb2 from "../assets/svgs/peopleicn  (2).svg";
import iconsb3 from "../assets/svgs/peopleicn  (3).svg";
import iconsb4 from "../assets/images/peopleicn (4).png";

const peopleData = [
  {
    id: 1,
    img: iconsb1,
    para: "4.9/5.0",
  },
  {
    id: 2,
    img: iconsb2,
    para: "4.9/5.0",
  },
  {
    id: 3,
    img: iconsb3,
    para: "4.9/5.0",
  },
  {
    id: 4,
    img: iconsb4,
    para: "4.9/5.0",
  },
  {
    id: 5,
    img: iconsb1,
    para: "4.9/5.0",
  },
  {
    id: 6,
    img: iconsb2,
    para: "4.9/5.0",
  },
  {
    id: 7,
    img: iconsb3,
    para: "4.9/5.0",
  },
  {
    id: 8,
    img: iconsb4,
    para: "4.9/5.0",
  },
  {
    id: 9,
    img: iconsb1,
    para: "4.9/5.0",
  },
  {
    id: 10,
    img: iconsb2,
    para: "4.9/5.0",
  },
  {
    id: 11,
    img: iconsb3,
    para: "4.9/5.0",
  },
  {
    id: 12,
    img: iconsb4,
    para: "4.9/5.0",
  },
  {
    id: 13,
    img: iconsb1,
    para: "4.9/5.0",
  },
  {
    id: 14,
    img: iconsb2,
    para: "4.9/5.0",
  },
  {
    id: 15,
    img: iconsb3,
    para: "4.9/5.0",
  },
  {
    id: 16,
    img: iconsb4,
    para: "4.9/5.0",
  },
  {
    id: 17,
    img: iconsb1,
    para: "4.9/5.0",
  },
  {
    id: 18,
    img: iconsb2,
    para: "4.9/5.0",
  },
  {
    id: 19,
    img: iconsb3,
    para: "4.9/5.0",
  },
  {
    id: 20,
    img: iconsb4,
    para: "4.9/5.0",
  },
  {
    id: 21,
    img: iconsb1,
    para: "4.9/5.0",
  },
  {
    id: 22,
    img: iconsb2,
    para: "4.9/5.0",
  },
  {
    id: 23,
    img: iconsb3,
    para: "4.9/5.0",
  },
  {
    id: 24,
    img: iconsb4,
    para: "4.9/5.0",
  },
  {
    id: 25,
    img: iconsb1,
    para: "4.9/5.0",
  },
  {
    id: 26,
    img: iconsb2,
    para: "4.9/5.0",
  },
  {
    id: 27,
    img: iconsb3,
    para: "4.9/5.0",
  },
  {
    id: 28,
    img: iconsb4,
    para: "4.9/5.0",
  },
];

function Peoplesec() {
  return (
    <div className="peoplebg py-[2rem]">
      <div>
        <h2 className="2xl:text-[44px] xl:text-[44px] md:text-[44px] sm:text-[22px] text-[22px] font-[700] text-center">
          Real People, Real Results: <br className="2xl:hidden xl:hidden lg:hidden md:hidden sm:flex flex " /> <span className="textgr">Hear from our customers</span>
        </h2>
      </div>
      <div className="carousel-wrapper py-[2rem]">
        <div className="carousel flex 2xl:space-x-[2rem] xl:space-x-[2rem] lg:space-x-[2rem] md:space-x-[2rem] sm:space-x-[1rem] space-x-[1rem]">
          {peopleData.map((item, index) => (
          
            <div key={index} className="flex  carousel-item justify-center shadow-xl items-center flex-col space-y-[15px] bg-[#fff] rounded-[15px] py-[30px] px-[65px]">
              <img className="h-[auto] w-[auto]" src={item.img} alt="" />
              <h4 className="2xl:text-[16px] xl:text-[16px] lg:text-[16px] md:text-[16px] sm:text-[12px] text-[12px] font-[700] text-[#000]">
                {item.para}
              </h4>
            </div>
          
        
          ))}
          {peopleData.map((item, index) => (
        
          <div key={index} className="flex  carousel-item justify-center shadow-xl items-center flex-col space-y-[15px] bg-[#fff] rounded-[15px] py-[30px] px-[65px]">
            <img className="h-[auto] w-[auto]" src={item.img} alt="" />
            <h4 className="2xl:text-[16px] xl:text-[16px] lg:text-[16px] md:text-[16px] sm:text-[12px] text-[12px] font-[700] text-[#000]">
              {item.para}
            </h4>
          </div>
          
      
          ))}
         
        </div>
      <p id="about"></p>
      </div>
    </div>
  );
}

export default Peoplesec;
