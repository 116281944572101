import React, { useState } from "react";
import { Link } from "react-router-dom";
import icon3 from "../assets/svgs/fot (3).svg";
import icon4 from "../assets/svgs/fot (4).svg";
import icon6 from "../assets/svgs/fot (6).svg";
import icon7 from "../assets/svgs/fot (7).svg";
import Logo from "../assets/Logo/low-size/LogoWht.jpg";
import ContactModel from "./ContactModel";

function Footer() {
  const [showModal, setShowModal] = useState(false);
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="bg-[#006083] ">
      <div className="max-w-[1200px] w-[90%] m-auto Navbar py-[4rem] 2xl:flex xl:flex lg:flex md:flex sm:block block items-start justify-between 2xl:space-y-[0rem] xl:space-y-[0rem] lg:space-y-[0rem] md:space-y-[0rem] smspace-y-[2rem] space-y-[2rem]">
        <div className="2xl:w-[30%] xl:w-[30%] lg:w-[30%] md:w-[30%] sm:w-[100%] w-[100%] space-y-[15px]">
          <div className="max-w-[100px]">
            <a href="/">
              <img src={Logo} alt="" />
            </a>
          </div>
          <p className="text-[16px] font-[400] text-[#fff]">
            Titan Movers USA is a premier moving company dedicated to providing
            top-notch relocation services across the United States. With a
            commitment to reliability, efficiency, and professionalism, Titan
            Movers USA has established itself as a trusted name in the moving
            industry.
          </p>
        </div>
        <div className="2xl:w-[20%] xl:w-[20%] lg:w-[20%] md:w-[20%] sm:w-[100%] w-[100%] ">
          <div className="">
            <h3 className="text-[#fff] text-[14px] font-[700]">Links</h3>
          </div>

          <Link to="/#about">
            <p className="text-[16px] font-[400] text-[#fff] pt-[15px]">
              About Us
            </p>
          </Link>
          <Link to="/#services">
            <p className="text-[16px] font-[400] text-[#fff] pt-[15px]">
              Services
            </p>
          </Link>
          <button
            onClick={openModal}
            className="text-[#fff] pt-[15px] text-[16px] font-[400]"
          >
            Contact Us
          </button>
          <ContactModel show={showModal} onClose={closeModal} />
          <Link to="/#faqs">
            <p className="text-[16px] font-[400] text-[#fff] pt-[15px] ">
              FAQS
            </p>
          </Link>
          <Link to="/privacy-policy">
            <p className="text-[16px] font-[400] text-[#fff] pt-[15px] ">
              Privacy Policy
            </p>
          </Link>
          <Link to="/terms-and-conditions">
            <p className="text-[16px] font-[400] text-[#fff] pt-[15px]">
              Terms & Conditions
            </p>
          </Link>
        </div>
        <div className="2xl:w-[20%] xl:w-[20%] lg:w-[20%] md:w-[20%] sm:w-[100%] w-[100%] space-y-[15px]">
          <div>
            <h3 className="text-[#fff] text-[16px] font-[700]">
              Contact Information
            </h3>
          </div>
          <div className="space-y-[15px]">
            <div className="flex space-x-3 items-center">
              <img src={icon7} alt="" />
              <p className="text-[16px] font-[400] text-[#fff]">
                info@titanmoversusa.com
              </p>
            </div>
            <a
                href="tel:+19012860303"
                className="2xl:hidden xl:hidden lg:hidden md:hidden sm:block block "
              >
                <div className="flex space-x-3 items-center">
              <img src={icon6} alt="" />
            
                <p className="text-[16px] font-[400] text-[#fff]">
                  (901) 286-0303
                </p>
            </div>
              </a>
            <div className="flex space-x-3 items-center 2xl:flex xl:flex lg:flex md:flex sm:hidden hidden ">
              <img src={icon6} alt="" />
              <p className=" text-[16px] font-[400] text-[#fff]">
                  (901) 286-0303
                </p>
            </div>
          </div>
        </div>
        <div className=" 2xl:w-[12%] xl:w-[12%] lg:w-[12%] md:w-[12%] sm:w-[100%] w-[100%] space-y-[15px]">
          <div>
            <h3 className="text-[#fff] text-[16px] font-[700]">
              Social Media Links
            </h3>
          </div>
          <div className="space-y-[15px]">
            <div className="flex space-x-3 items-center">
              <img src={icon4} alt="" />
              <a
                href="https://www.facebook.com/titanmoversusa"
                target="_blank"
                rel="noreferrer"
                className="text-[16px] font-[400] text-[#fff]"
              >
                Facebook
              </a>
            </div>
            <div className="flex space-x-3 items-center">
              <img src={icon3} alt="" />
              <a
                href="https://www.instagram.com/titanmoversusa/"
                target="_blank"
                rel="noreferrer"
                className="text-[16px] font-[400] text-[#fff]"
              >
                Instagram
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="2xl:py-[24px] xl:py-[24px] lg:py-[24px] md:py-[24px] sm:py-[16px] py-[16px] border-t border-[#818181]">
        <p className="text-[#fff] 2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[12px] text-[12px] text-center font-[400]">
          © 2024– All Rights Reserved
        </p>
      </div>
    </div>
  );
}

export default Footer;
