import React from "react";
import thkimg from "../assets/images/thkimg.png";
import Cose from "../assets/images/cose.png";
function ContactModel({ show, onClose }) {
  if (!show) return null;

  return (
    <>
      <div className="bg-[#00000087] flex items-center 2xl:top-0 xl:top-0 lg:top-0 md:top-0 sn:top-0 -top-5  fixed w-full right-0 h-[100vh] z-[99] m-auto">
        <div className="modal-overlayco 2xl:w-[70%] xl:w-[70%] lg:w-[70%] md:w-[70%] sm:w-[95%] w-[95%] m-auto bg-[#fff]  pb-[3rem] ">
          <div className="flex justify-end px-[1rem] py-[1rem]">
            <button onClick={onClose}>
              {/* <img className="" src={Cose} alt="" /> */}
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 18L18 6M6 6L18 18"
                  stroke="black"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div className="flex modal-content items-center justify-center h-[100%]">
            <div className="bg-[#fff] thkshad max-w-[700px] 2xl:w-[100%] xl:w-[100%] lg:w-[100%] md:w-[100%] sm:w-[90%] w-[90%] rounded-[10px] 2xl:p-[4rem] xl:p-[4rem] lg:p-[4rem]md:p-[4rem] sm:p-[2rem] p-[2rem]  boxshadow flex flex-col  space-y-[30px]">
              <div>
                <h3 className="2xl:text-[44px] xl:text-[44px] lg:text-[44px] md:text-[44px] sm:text-[22px] text-[22px] font-[700]">
                  Contact Us!
                </h3>
                <p className="text-[14px] font-[400]">
                  Let us know more about you and we’ll get in touch as soon as
                  we can.
                </p>
              </div>
              <div className="space-y-[20px]">
                <div className="flex justify-between space-x-[1rem] items-center">
                  <div className="w-[100%]">
                    <div className="flex h-[38px] bg-[#F8F8F8] rounded-[3px] justify-between items-center px-2 w-[100%]">
                      <input
                        type="text"
                        className="2xl:text-[14px] xl:text-[14px] lg:text-[14px] md:text-[14px] sm:text-[12px] text-[12px] h-[38px] w-[100%] bg-[#F8F8F8] outline-none"
                        placeholder="First name"
                      />
                    </div>
                    <div className="bgborder"></div>
                  </div>
                  <div className="w-[100%]">
                    <div className="flex h-[38px] bg-[#F8F8F8] rounded-[3px] justify-between items-center px-2 w-[100%]">
                      <input
                        type="text"
                        className="2xl:text-[14px] xl:text-[14px] lg:text-[14px] md:text-[14px] sm:text-[12px] text-[12px] h-[38px] w-[100%] bg-[#F8F8F8] outline-none"
                        placeholder="Last name"
                      />
                    </div>
                    <div className="bgborder"></div>
                  </div>
                </div>
                <div className="flex justify-between space-x-[1rem] items-center">
                  <div className="w-[100%]">
                    <div className="flex h-[38px] bg-[#F8F8F8] rounded-[3px] justify-between items-center px-2 w-[100%]">
                      <input
                        type="email"
                        className="2xl:text-[14px] xl:text-[14px] lg:text-[14px] md:text-[14px] sm:text-[12px] text-[12px] h-[38px] w-[100%] bg-[#F8F8F8] outline-none"
                        placeholder="Enter your email"
                        required
                      />
                    </div>
                    <div className="bgborder"></div>
                  </div>
                  <div className="w-[100%]">
                    <div className="flex h-[38px] bg-[#F8F8F8] rounded-[3px] justify-between items-center px-2 w-[100%]">
                      <input
                        type="text"
                        className="2xl:text-[14px] xl:text-[14px] lg:text-[14px] md:text-[14px] sm:text-[12px] text-[12px] h-[38px] w-[100%] bg-[#F8F8F8] outline-none"
                        placeholder="Phone number"
                      />
                    </div>
                    <div className="bgborder"></div>
                  </div>
                </div>
                <div>
                  <textarea
                    name=""
                    placeholder="Message"
                    className="p-2 outline-none bg-[#F8F8F8] rounded-[3px] w-[100%] "
                    minLength="300px"
                    id=""
                  ></textarea>
                  <div className="bgborder"></div>
                </div>
                <div className="flex justify-center items-center">
                  <button className="w-[100%]  2xl:max-w-[180px] xl:max-w-[180px] lg:max-w-[180px] md:max-w-[180px] sm:max-w-[150px] max-w-[150px] rounded-[5px] h-[48px] navbtn hover:!bg-transparent hover:border hover:border-[#006083] hover:text-[#000] text-[#fff] font-[700] 2xl:text-[16px] xl:text-[16px] lg:text-[16px] md:text-[16px] sm:text-[14px] text-[14px]">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactModel;
