import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";

import callicon from "../assets/svgs/call.svg";
import callicon2 from "../assets/images/hvi.png";
import Logo from "../assets/Logo/logo.svg";
import Menu from "../assets/svgs/Menu.svg";
import ContactModel from "./ContactModel";

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const menuRef = useRef(null);
  const menuIconRef = useRef(null);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const toggleMenuclose = () => {
    setIsMenuOpen(false);
  };

  const handleClickOutside = (event) => {
    if (
      isMenuOpen &&
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      menuIconRef.current &&
      !menuIconRef.current.contains(event.target)
    ) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };

    //eslint-disable-next-line
  }, [isMenuOpen]);

  return (
    <div className="max-w-[1200px]  w-[90%] m-auto Navbar 2xl:py-[10px] xl:py-[10px] lg:py-[10px] md:py-[10px] sm:py-[10px] py-[10px]  flex items-center justify-between">
      <div className="2xl:max-w-[82px] xl:max-w-[76px] lg:max-w-[62px] md:max-w-[62px] sm:max-w-[100px] max-w-[60px] w-[100%]">
        <a href="/">
          <img src={Logo} className="h-[auto] w-[100%]" alt="" />
        </a>
      </div>
      <div className="2xl:w-[74%] xl:w-[75%] lg:w-[76%] md:w-[76%] sm:w-[76%] w-[76%] 2xl:flex xl:flex lg:flex md:hidden sm:hidden hidden justify-end space-x-[2rem]">
        <Link to="#about">
          <p className="text-[16px] font-[400] ">About Us</p>
        </Link>
        <Link to="#services">
          <p className="text-[16px] font-[400] ">Services</p>
        </Link>
        <Link to="#reviews">
          <p className="text-[16px] font-[400]">Reviews</p>
        </Link>
        <button onClick={openModal} className="text-[16px] font-[400]">
          Contact Us
        </button>
        <ContactModel show={showModal} onClose={closeModal} />
      </div>
      <div className="2xl:space-x-0 xl:space-x-0 lg:space-x-0 flex items-center md:space-x-0 sm:space-x-2 space-x-2">
        <button
          className="2xl:flex xl:flex lg:flex md:flex sm:hidden hidden 2xl:w-[180px] xl:w-[180px] lg:w-[180px] md:w-[180px] sm:w-[156px] w-[156px] hover:bg-transparent hover:border hover:border-[#006083] hover:text-[#000]  2xl:h-[49px] xl:h-[49px] lg:h-[49px] md:h-[49px] sm:h-[33px] h-[33px] navbtn 2xl:text-[14px] xl:text-[14px] lg:text-[14px] md:text-[14px] sm:text-[14px] text-[14px] text-white font-bold flex items-center justify-center rounded-[5px]"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <img
            src={isHovered ? callicon2 : callicon}
            alt=""
            className="2xl:mr-3 xl:mr-3 lg:mr-3 md:mr-3 sm:mr-2 mr-1 "
          />
          (901) 286-0303
        </button>
        <a
          href="tel:+19012860303"
          className="2xl:hidden xl:hidden lg:hidden md:hidden sm:block block "
        >
          <button
            className="2xl:w-[180px] xl:w-[180px] lg:w-[180px] md:w-[180px] sm:w-[156px] w-[156px] hover:bg-transparent hover:border hover:border-[#006083] hover:text-[#000] 2xl:h-[49px] xl:h-[49px] lg:h-[49px] md:h-[49px] sm:h-[37px] h-[37px] navbtn 2xl:text-[14px] xl:text-[14px] lg:text-[14px] md:text-[14px] sm:text-[14px] text-[14px] text-white font-bold flex items-center justify-center rounded-[5px]"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <img
              src={isHovered ? callicon2 : callicon}
              alt=""
              className="2xl:mr-3 xl:mr-3 lg:mr-3 md:mr-3 sm:mr-2 mr-1"
            />
            (901) 286-0303
          </button>
        </a>
        <div
          className="2xl:hidden xl:hidden lg:hidden md:block sm:block block"
          ref={menuIconRef}
        >
          <img src={Menu} className="" onClick={toggleMenu} alt="" />
        </div>
      </div>
      {isMenuOpen && (
        <div
          ref={menuRef}
          className="space-y-[15px] flex justify-center items-center flex-col z-[99] absolute left-[0%] top-[99%] py-[2rem] bg-[#fff] w-[100%]"
        >
          <Link to="/#about">
            <button
              onClick={toggleMenuclose}
              className="text-[16px] font-[400] "
            >
              About Us
            </button>
          </Link>
          <Link to="/#services">
            <button
              onClick={toggleMenuclose}
              className="text-[16px] font-[400] "
            >
              Services
            </button>
          </Link>
          <Link to="/#reviews">
            <button
              onClick={toggleMenuclose}
              className="text-[16px] font-[400]"
            >
              Reviews
            </button>
          </Link>
          <button onClick={openModal} className="text-[16px] block font-[400]">
            Contact Us
          </button>
          <ContactModel show={showModal} onClose={closeModal} />
        </div>
      )}
    </div>
  );
}

export default Navbar;
