import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/pagination';
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css/navigation";

const cardsData = [
  {
    id: 1,
    title: "Residential Moving",
    description:
      "Titan Movers USA is your trusted partner for residential moving. Our experienced local movers ensure a smooth and stress-free transition for you and your family. We handle relocations of all sizes, from cozy studios to sprawling family homes. Our expert team is trained in careful handling and packing techniques, guaranteeing your belongings arrive safely at your new destination. Let us take care of the heavy lifting and packing logistics, so you can focus on the excitement of settling into your new home.",
    bgClass: "bgimgcard1",
  },
  {
    id: 2,
    title: "Commercial Moving",
    description:
      "Titan Movers USA specializes in commercial moving and understands the critical role efficiency plays in business operations. Our team of expert movers is dedicated to minimizing downtime, allowing you to focus on what matters most – running your business. We offer seamless and efficient relocation services, including packing, transport, and setup, to ensure a smooth transition to your new workspace.",
    bgClass: "bgimgcard2",
  },
  {
    id: 3,
    title: "Long Distance Moving",
    description:
      "Titan Movers USA excels in long-distance moving. Our experienced team and extensive resources ensure your belongings arrive safely at your new home, regardless of the distance. We understand the unique challenges of cross-country moves and prioritize clear communication throughout the process. Trust us to handle your long-distance relocation with care and efficiency.",
    bgClass: "bgimgcard3",
  },
  {
    id: 4,
    title: "Packing Services",
    description:
      "Titan Movers USA's professional packing services are here to save the day! Short on time or lacking packing expertise? No problem! Our experienced team will meticulously wrap and box your belongings using high-quality packing materials. We take pride in our careful packing techniques, providing you with peace of mind that your items will arrive safely at your new home. Let our packing services simplify your move and protect your treasured possessions.",
    bgClass: "bgimgcard4",
  },
  {
    id: 5,
    title: "College Dorms",
    description:
      "Don't waste precious time struggling with heavy furniture and overflowing boxes. Titan Movers USA offers efficient college dorm moving services. Whether you're moving in or out, we'll help you navigate the often-chaotic dorm move-in/out process stress-free. Focus on starting your exciting college journey while we handle the heavy lifting.",
    bgClass: "bgimgcard5",
  },
  {
    id: 6,
    title: "Junk Removal",
    description:
      "Titan Movers USA is here to help! We understand that clutter can accumulate over time. Our friendly and efficient team will haul away your old furniture, appliances, and other debris. Let us take care of the heavy lifting and junk removal, leaving your home or office clean and clutter-free, giving you back the valuable space you deserve.",
    bgClass: "bgimgcard6",
  },
];

function TypesofServicesSec() {
  return (
    <div className="max-w-[1200px] w-[90%] m-auto pt-[4rem] 2xl:pb-[3.4rem] xl:pb-[3.4rem] lg:pb-[3.4rem] md:pb-[3.4rem] sm:pb-[4rem] pb-[4rem] ">
      <div className=" 2xl:pt-[2rem] xl:pt-[2rem] lg:pt-[2rem] md:pt-[2rem] sm:pt-[0rem] pt-[0rem] 2xl:space-y-[2rem] xl:space-y-[2rem] lg:space-y-[2rem] md:space-y-[2rem] sm:space-y-[30px] space-y-[30px]">
        <div>
          <h2 className="textgr 2xl:text-[44px] xl:text-[44px] md:text-[44px] sm:text-[22px] text-[22px]  font-[700] text-center">
            Types of Services We Offer
          </h2>
        </div>
        <div className="dots 2xl:flex xl:flex lg:flex md:flex sm:hidden hidden justify-between 2xl:space-x-[3rem] xl:space-x-[3rem] lg:space-x-[3rem] md:space-x-[3rem] sm:space-x-[0rem] space-x-[0rem] 2xl:space-y-[0rem] xl:space-y-[0rem] lg:space-y-[0rem] md:space-y-[0rem] sm:space-y-[2rem] space-y-[2rem]">
          <Swiper
            spaceBetween={0}
            navigation={true}
            pagination={{
              clickable: true,
            }}
            modules={[Navigation, Pagination]}
            className="mySwiper !px-[1.2rem] !h-[430px] "
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 0,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 0,
              },
              1440: {
                slidesPerView: 4,
                spaceBetween: 0,
              },
              1640: {
                slidesPerView: 4,
                spaceBetween: 0,
              },
            }}
          >
            {cardsData.map((card) => (
              <SwiperSlide key={card.id}>
                <div
                  key={card.id}
                  className={`card-container max-w-[250px] m-auto w-[100%] 2xl:h-[380px] xl:h-[380px] lg:h-[380px] md:h-[380px] sm:h-[520px] h-[520px] ${card.bgClass}`}
                >
                  <div className="card-content flex !justify-between flex-col pt-4 pb-6">
                    <p className="text-[14px] font-[400] text-[#fff] py-[20px] px-2 text-center">
                      {card.description}
                    </p>
                    <h4 className="text-[16px] font-[700] text-[#fff]">
                      {card.title}
                    </h4>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="2xl:hidden xl:hidden lg:hidden md:hidden sm:block block">
          <Swiper
            spaceBetween={30}
            slidesPerView={1}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className="mySwiper !h-[770px] "
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
            }}
          >
            {cardsData.map((card) => (
              <SwiperSlide key={card.id}>
                <div className="p-8 bg-[#f5f5f5ee] rounded-[10px] h-[725px]">
                  <div
                    className={`w-[100%] h-[340px]  !rounded-[10px]  !bg-center ${card.bgClass}`}
                  >
                   <div className="shadowcard rounded-b-[10px] pb-4 flex justify-center items-end  w-[100%] h-[100%]">
                   <h4 className="text-[16px] font-[700] text-[#fff]">
                      {card.title}
                    </h4>
                   </div>
                  </div>
                  <div className="">
                    <p className="2xl:text-[14px] xl:text-[14px] lg:text-[14px] md:text-[14px] sm:text-[16px] text-[16px] font-[400] text-[#000] pt-[20px] ">
                      {card.description}
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="flex justify-center">
          
          <a href="#hero" className="flex justify-center w-[100%] max-w-[350px] m-auto">
          <button className="w-[100%] hover:opacity-[0.9] 2xl:max-w-[350px] xl:max-w-[350px] lg:max-w-[350px] md:max-w-[350px] sm:max-w-[250px] max-w-[250px] rounded-[5px] h-[48px] bgbrcolor text-[#fff] font-[700] text-[18px]">
            Get Your Quote Now
          </button>
              </a>
        </div>
      </div>
    </div>
  );
}

export default TypesofServicesSec;
