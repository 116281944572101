import React, { useState } from "react";
import faqimg from "../assets/images/low-size/faq.png";

const FAQItem = ({ question, answer, isOpen, onClick}) => {

  return (
    <div 
    className={`px-4  border-[2px] rounded-[8px] border-[#006083]  items-center justify-between mb-4 ${!isOpen ? "2xl:h-[80px] xl:h-[80px] lg:h-[80px] md:h-[80px] sm:h-[75px] h-[75px] flex" : "block h-[100%] py-6"}`}
    >
      <div
        className="flex justify-between w-[100%] items-start cursor-pointer "
        onClick={onClick}

      >
        <h4 className={`text-[16px] font-[500] ${!isOpen ? "text-black" : "text-[#006083]"}`}>{question}</h4>
        {isOpen ? (
          <>
            <span className="text-[20px] font-[700] leading-[100%]">-</span>
          </>
        ) : (
          <>
            <span className="text-[20px] font-[700] leading-[100%]">+</span>
          </>
        )}
      </div>
      {isOpen && <div className="text-[14px] font-[400] mt-2 ">{answer}</div>}
    </div>
  );
};

const FAQSection = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const faqs = [
    {
      question: "How far in advance should I get an estimate?",
      answer: "To ensure we have the availability to move you on your preferred date, we recommend contacting Movers at least 4 weeks in advance. With more lead time, we can lock in your spot and create a seamless moving experience. Don't wait - reach out today and let's get your move scheduled!",
    },
    {
      question: "How will the company protect my vehicle during transportation?",
      answer: "We take the hassle out of packing! Our movers disassemble, wrap, and crate your belongings, ensuring everything arrives safe and sound.  Whether it's a full packing service or just the trickier items, we've got you covered. Some services are complimentary, while others have a small additional cost. Our sales team will explain everything clearly.",
    },
    {
      question: "Will the company assemble and de-assemble my furniture for me?",
      answer: "We don't just move your stuff, we settle you in!  Our movers expertly disassemble furniture for transport, then  reassemble everything and place it in the designated rooms at your new home.  Labeled boxes get delivered to the right spot, saving you precious unpacking time.",
    },
    {
        question: "Should I Pack My Important Documents Separately?",
        answer: "Don't let your essential documents get lost in the moving shuffle! Invest in a waterproof sleeve for extra protection. Pack them separately and consider carrying them with you for ultimate security.  Find a designated spot in your new home to store them upon arrival. By following these simple steps, you can ensure your important documents stay safe during your move.",
      },
  ];

  const handleClick = (index) => {
    setOpenIndex(index === openIndex ? null : index);
  };

  return (
    <div>
      {faqs.map((faq, index) => (
        <FAQItem key={index} question={faq.question} isOpen={index === openIndex} onClick={() => handleClick(index)} answer={faq.answer} />
      ))}
    </div>
  );
};

function FaqsSection() {
  return (
    <div className="max-w-[1200px] w-[90%] m-auto 2xl:pt-[5rem] xl:pt-[5rem] lg:pt-[5rem] md:pt-[5rem] sm:pt-[3rem] pt-[3rem] 2xl:pb-[10rem] xl:pb-[10rem] lg:pb-[10rem] md:pb-[10rem] sm:pb-[3rem] pb-[3rem]">
      <div className="w-[100%] 2xl:space-y-[4rem] xl:space-y-[4rem] lg:space-y-[4rem] md:space-y-[4rem] sm:space-y-[2rem] space-y-[2rem]">
        <div>
          <h2 className="2xl:text-[32px] xl:text-[32px] lg:text-[32px] md:text-[32px] sm:text-[22px] text-[22px] font-[700] textgr2 text-center">FAQs</h2>
        </div>
        <div className="2xl:flex xl:flex lg:flex md:flex sm:block block justify-between 2xl:space-x-[2rem] xl:space-x-[2rem] lg:space-x-[2rem] md:space-x-[2rem] sm:space-x-[0rem] space-x-[0rem]">
          <div className="2xl:block xl:block lg:block md:block sm:hidden hidden 2xl:w-[50%] xl:w-[50%] lg:w-[50%] md:w-[50%] sm:w-[100%] w-[100%] relative">
            <img src={faqimg} className="absolute right-[-10%] top-[-5%] z-[-1] w-[100%]" alt="FAQ" />
          </div>
          <div className="2xl:w-[50%] xl:w-[50%] lg:w-[50%] md:w-[50%] sm:w-[100%] w-[100%]">
            <FAQSection />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FaqsSection;
